const { initializeTarteAuCitron } = require("./loader/tarteaucitron-loader");
const { bindAosClasses } = require("./serializer/aos-serializer");
const { initializeHeroSlider } = require("./slider/hero-slider");

document.addEventListener("DOMContentLoaded", () => {
  // Loaders
  initializeHeroSlider();
  initializeTarteAuCitron();

  // Bindings
  bindAosClasses();
});
