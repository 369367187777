const $ = require("jquery");

exports.initializeHeroSlider = () => {
  const sliderForServices = $(".slider-for-services");
  const sliderForBlog = $(".slider-for-blog");
  const sliderConfiguration = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    centerMode: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (sliderForServices && sliderForServices.length > 0) {
    sliderForServices.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: ".slider-nav-services",
      autoplay: true,
      autoplaySpeed: 2500,
      infinite: true,
    });

    sliderConfiguration.asNavFor = ".slider-for-services";
    sliderConfiguration.prevArrow = $(".prev-service");
    sliderConfiguration.nextArrow = $(".next-service");
  }

  $(".slider-nav-services").slick(sliderConfiguration);

  if (sliderForBlog && sliderForBlog.length > 0) {
    sliderForBlog.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: ".slider-nav-services",
      autoplay: true,
      autoplaySpeed: 2500,
      infinite: true,
    });

    sliderConfiguration.asNavFor = ".slider-for-blog";
    sliderConfiguration.prevArrow = $(".prev-blog");
    sliderConfiguration.nextArrow = $(".next-blog");
  }

  $(".slider-nav-blog").slick(sliderConfiguration);
};
