const AOS = require("aos");

/**
 * @param string {string}
 * @returns {object}
 */
const matches = (string) => {
  let matches = null;
  const tab = [
    "data-aos_",
    "data-aos-delay_",
    "data-aos-duration_",
    "data-aos-easing_",
  ];

  Object.values(tab).forEach(function (value) {
    if (String(string).match(value)) {
      matches = string.split("_");
      return false;
    }
  });

  return matches;
};

/** @returns {void} */
exports.bindAosClasses = () => {
  const animations = document.querySelectorAll(".animation");

  if (animations) {
    for (const animation of animations) {
      const tab = animation.classList;

      Object.values(tab).forEach((item) => {
        let attribute = matches(item);

        if (attribute !== null) {
          animation.setAttribute(attribute[0], attribute[1]);
        }
      });

      AOS.init();
    }
  }
};
